import { useSession } from "next-auth/react";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useAccountList } from "utils/clientAPIs/accountsCheck";

interface PasswordContextValue {
  encryptedPasswords: any;
  isLoading: boolean;
  accounts: any;
  isAccountLoading: boolean;
  currentAccount: any;
  setCurrentAccount: any;
  passwordError: number;
  setRefeshCurrentAccount: any;
  accountCancelSuccess: any;
  setAccountCancelSuccess: any;
  mutate: any;
}

const KBContext = createContext<PasswordContextValue | undefined>(undefined);
// 비밀번호 업데이트
export const updatePasswordToRedis = async (
  password: string,
  email: string | null,
  accountNo: string
) => {
  console.log("accountNo", accountNo);
  // if (!session?.user?.email) return;
  if (!email) return;
  await fetch("/api/kb/securePassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      key: "encryptedPassword",
      value: password,
      email: email,
      accountNo: accountNo,
    }),
  });
};

// Redis 에서 캐시 비밀번호 가져오기 -> 비밀번호 전역 상태 업데이트
const getPasswordFromRedis = async (
  accountNo: string,
  setIsLoading: any,
  email: string,
  setEncryptedPasswords: any
) => {
  setIsLoading(true);
  console.log("getPasswordFromRedis", email, accountNo);
  const res = await fetch(
    `/api/kb/securePassword?email=${email}&key=encryptedPassword&accountNo=${accountNo}`
  );
  const data = await res.json();
  console.log("data", data);
  if (data?.password)
    setEncryptedPasswords((prev: any) => ({
      ...prev,
      [accountNo]: data?.password,
    }));
  setIsLoading(false);
};

export const KBProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: session, status } = useSession();

  // 계좌 관련
  // 계좌 정보 가져오기
  const [accounts, isAllAccountLoading, mutate, error] = useAccountList();
  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [currentAccount, setCurrentAccount] = useState<any>(null);
  const [accountCancelSuccess, setAccountCancelSuccess] = useState<any>(false);
  const [refeshCurrentAccount, setRefeshCurrentAccount] =
    useState<boolean>(false);

  // currentAccount 설정 -> Default:대표계좌

  useEffect(() => {
    if (accounts?.length > 0) {
      mutate();
    }
  }, [refeshCurrentAccount]);

  useEffect(() => {
    console.log("isAllAccountLoading", isAllAccountLoading);
    console.log("error", error);
    if (isAllAccountLoading) {
      return;
    }
    if (error) {
      setIsAccountLoading(false);

      return;
    }
    // if (!session) {
    //   return;
    // }
    console.log("accounts", accounts);
    if (accounts?.length > 0) {
      const mainAccount =
        accounts?.find((account: any) => account.isMainAccount) || null;
      if (mainAccount) {
        setCurrentAccount(mainAccount);
      }
      setIsAccountLoading(false);
    } else {
      // 계좌가 없을 때
      if (session?.user?.email) {
        setIsAccountLoading(false);
      }
    }
  }, [isAllAccountLoading, error, session, accounts]);

  //////////////////////////////
  //////////////////////////////
  // 비밀번호 관련
  const [encryptedPasswords, setEncryptedPasswords] = useState<any>(null);
  const [passwordError, setPasswordError] = useState<number>(0);
  const [reloadPassword, setReloadPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // 캐시 비밀번호 불러오는 로딩

  const checkPasswordValidation = async (
    password: string,
    accountNo: string
  ) => {
    // 비밀번호 체크
    const res = await fetch(
      `/api/kb/account/checkpassword?displayAccountNo=${accountNo}&password=${password}`
    );
    const data = await res.json();
    if (res?.status === 422) {
      const cnt = data?.errorCnt;
      setPasswordError(cnt);
      return false;
    } else {
      if (res?.status === 200) {
        setPasswordError(0);
        setEncryptedPasswords((prev: any) => {
          if (!prev) return { [currentAccount.accountNo]: password };
          return {
            ...prev,
            [currentAccount.accountNo]: password,
          };
        });
        updatePasswordToRedis(
          password,
          session?.user?.email as string,
          accountNo
        );
      }
    }
  };
  const handleMessage = (event: MessageEvent) => {
    if (event.data && event.data.encryptedPassword) {
      const encryptedPasswordData = JSON.parse(event.data.encryptedPassword);
      const password = encryptedPasswordData.dataBody?.vkpad_value1;
      console.log("handleMessage");
      checkPasswordValidation(password, currentAccount.accountNo);
    } else {
      console.log("event.data.encryptedPassword is null");
    }
  };
  // 보안키패드 화면에서 비밀번호 GET
  useEffect(() => {
    if (status !== "authenticated") return; // 세션 데이터가 로드될 때까지 대기
    if (!currentAccount || !currentAccount?.accountNo) return;

    console.log("addEventListener");
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [session, currentAccount]);

  // Redis 에서 캐시 비밀번호 GET
  useEffect(() => {
    if (!accounts) return;
    if (!currentAccount || !currentAccount?.accountNo) return;
    if (session && session.user) {
      if (!encryptedPasswords) console.log("getPasswordFromRedis");
      getPasswordFromRedis(
        currentAccount.accountNo,
        setIsLoading,
        session.user.email as string,
        setEncryptedPasswords
      );
    }
  }, [session, currentAccount]);

  return (
    <KBContext.Provider
      value={{
        encryptedPasswords,
        isLoading,
        accounts,
        isAccountLoading,
        currentAccount,
        setCurrentAccount,
        passwordError,
        setRefeshCurrentAccount,
        accountCancelSuccess,
        setAccountCancelSuccess,
        mutate,
      }}
    >
      {children}
    </KBContext.Provider>
  );
};

export const useKBGlobalState = (): PasswordContextValue => {
  const context = useContext(KBContext);
  if (!context) {
    throw new Error("usePassword must be used within a KBProvider");
  }
  return context;
};
