import GrayToggleButton from "components/common/buttons/toggle/GrayToggleButton";
import { useRouter } from "next/router";
import { useState, useEffect, useMemo } from "react";
import { TYPO } from "styles/Typo";
import List from "components/common/List/list";
import MyStockSortModal from "components/modals/KB/MyStockSortModal";
import { Forward } from "components/common/Icons";
import { useKBGlobalState } from "contexts/KBContext";
import {
  useKbTotalEvalAmout,
  useKbTotalValuations,
} from "utils/clientAPIs/accountsCheck";
import {
  chgFormat,
  convertCurrentPrice,
  convertCurrentPriceChange,
  convertTotalVarPl,
  convertvalAmt,
  convertvalPl,
  selectColor,
} from "utils/kb/convertCurr";
import { KOREA } from "utils/constant/ITME_INFO";
import CantGoDetailModal from "components/modals/KB/CantGoDetilModal";
import KbConnectAd from "./KbConnectAd";
import HighTotalScoreStocks from "./HighTotalScoreStocks";
import DividerLine from "components/common/divider/line";
import StrateRanking from "./StrateRanking";
import MyStockService from "./Service";

const ToLocaleString = ({
  data,
  min = 0,
  max = 0,
}: {
  data: any;
  min?: number;
  max?: number;
}) => {
  console.log("data11", data);
  if (data === undefined || data === null) return "";

  return data.toLocaleString("en-us", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

function MyStockLists({
  setIsValuationError,
  isLoginUser,
}: {
  setIsValuationError: any;
  isLoginUser: boolean;
}) {
  const { currentAccount, isAccountLoading } = useKBGlobalState();

  // 보유 종목 정보 (수량, 평가금, 평가손익 ()) -> 환율 전환
  const [valuationData, isValuationLoading, valuationMuuate, valuationError] =
    useKbTotalValuations(currentAccount?.accountNo || null);

  const [totalValuation, isTotalValuationLoading, mutate, totalValuationError] =
    useKbTotalEvalAmout(currentAccount?.accountNo || null);

  const [currToggle, setCurrToggle] = useState(true);
  const [priceToggle, setPriceToggle] = useState(true);
  const router = useRouter();
  const [cantGoDetail, setCantGoDetail] = useState(false);

  // 총 평가금액, 평가 손익, 수익률
  //

  const [sortIndex, setSortIndex] = useState(0); // 정렬 인덱스 0. 총 수익 높은 순 1. 일간 수익 높은 순 2. 이름 순
  const [sortModal, setSortModal] = useState(false); // 정렬 모달
  const sortedItems = useMemo(() => {
    if (valuationData && valuationData.length > 0) {
      let nonePriceStock;
      if (!priceToggle) {
        nonePriceStock = valuationData.filter(
          (data: any) => data.currentPriceChg === null
        );
      }

      let so =
        sortIndex === 0
          ? valuationData &&
            valuationData.length > 0 &&
            valuationData.sort((a: any, b: any) => b.valPl - a.valPl)
          : sortIndex === 1
          ? valuationData &&
            valuationData.length > 0 &&
            valuationData.sort(
              (a: any, b: any) => b?.currentPriceChg - a?.currentPriceChg
            )
          : valuationData.sort((a: any, b: any) =>
              a.ITEM_KR_NM.localeCompare(b.ITEM_KR_NM)
            );

      so = nonePriceStock?.length
        ? so.filter((data: any) => data.currentPriceChg !== null)
        : so;
      return nonePriceStock?.length ? [...so, ...nonePriceStock] : [...so];
    }
    return [];
  }, [valuationData, sortIndex]);

  const ListTemp = ({ data }: { data: any }) => {
    if (!data) return null;
    const LOC = data?.LOC || "";
    const ticker = LOC === KOREA ? data?.ITEM_CD_DL : data?.ITEM_CD_DL;
    return (
      <div
        onClick={() => {
          if (data.currentPriceChg === null) {
            setCantGoDetail(true);
          } else router.push(`/detail/${ticker}`);
        }}
        className="cursor-pointer"
      >
        <List
          HR_ITEM_NM={""}
          src={
            `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${ticker}.png` ||
            "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
            // `/images/logos/${data.ticker}.png` || "/images/logos/errorLogo.png"
          }
          item={router.locale === "ko" ? data?.ITEM_KR_NM : data?.ITEM_KR_NM}
          price={`${
            data?.quentityP6
              ? data.quentityP6.toLocaleString("en-us", {
                  maximumFractionDigits: 7,
                  minimumFractionDigits: 4,
                })
              : data.quantity.toLocaleString("en-us", {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
          }${router.locale === "ko" ? "주" : "주"}`}
          rightIcon={() => (
            <>
              <div
                className={`flex items-end flex-col
                
                `}
              >
                <span className={`${TYPO.TYPO5_1_SB}  `}>
                  {!priceToggle
                    ? convertCurrentPrice(currToggle, data)
                    : convertvalAmt(currToggle, data)}
                </span>
                <div
                  style={{
                    display:
                      data.currentPriceChg === null && !priceToggle
                        ? "none"
                        : "",
                  }}
                  className={`${TYPO.TYPO7_1_RG} gap-1
                ${
                  !priceToggle
                    ? !data?.currentPriceChg
                      ? ""
                      : Number(data?.currentPriceChg) > 0 &&
                        data?.currentPriceChg?.toFixed(1) !== "0.0"
                      ? "text-red40"
                      : Number(data?.currentPriceChg) === 0 ||
                        data?.currentPriceChg?.toFixed(1) === "0.0"
                      ? "text-gray50"
                      : "text-blue40"
                    : Number(data?.valYldP2) > 0 &&
                      data?.valYldP2.toFixed(1) !== "0.0"
                    ? "text-red40"
                    : Number(data?.valYldP2) === 0 ||
                      data?.valYldP2.toFixed(1) === "0.0"
                    ? "text-gray50"
                    : "text-blue40"
                }
                `}
                >
                  <span>
                    {!priceToggle
                      ? data.currentPriceChg === null
                        ? ""
                        : convertCurrentPriceChange(currToggle, data)
                      : convertvalPl(currToggle, data)}
                  </span>
                  <span>
                    {"("}
                    {!priceToggle
                      ? data.currentPriceChg === null
                        ? ""
                        : chgFormat(data?.currentPriceChg)
                      : chgFormat(data?.valYldP2)}
                    {"%)"}
                  </span>
                </div>
              </div>
            </>
          )}
        />
      </div>
    );
  };

  useEffect(() => {
    if (valuationError) {
      // +평가 금액 api 에러 발생 시
      setIsValuationError(true);
    }
  }, [valuationError]);

  if (isValuationLoading || isAccountLoading || isTotalValuationLoading)
    return (
      <div>
        <div className="bg-white w-full  flex items-center justify-center">
          {/* <Loading /> */}
        </div>
      </div>
    );

  if (valuationError || totalValuationError) return <div></div>;

  // if (valuationData.length === 0)
  //   return <div className="w-full h-[50vh] p-5">보유 종목이 없습니다</div>;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        <div>
          <div className="flex pt-6 px-6 justify-between items-center">
            <span className={`${TYPO.TYPO5_B} text-gray80`}>내 종목</span>
          </div>
          {valuationData?.length > 0 && (
            <div className="flex flex-col gap-1 py-2 px-6">
              <span className={`${TYPO.TYPO2_SB} text-gray90`}>
                {totalValuation?.totalValAmt.toLocaleString("en-us") || 0}원
              </span>
              <span
                className={`${TYPO.TYPO7_RG} ${
                  Number((totalValuation?.totalPlRate).toFixed(1)) > 0
                    ? "text-red40"
                    : (totalValuation?.totalPlRate).toFixed(1) === "0.0" ||
                      !totalValuation?.totalPlRate
                    ? "text-gray50"
                    : "text-blue40"
                }`}
              >
                {`${convertTotalVarPl(totalValuation)}`}
                {`(${(totalValuation?.totalPlRate).toLocaleString("en-us", {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })}%)`}
              </span>
            </div>
          )}
        </div>
        {valuationData?.length === 0 && (
          <div className="px-6 py-2">
            <span className={`${TYPO.TYPO2_SB} text-gray40`}>
              보유 중인 종목이 없어요
            </span>
          </div>
        )}
        {valuationData?.length > 0 && (
          <>
            <div className="flex items-center justify-between px-6 py-2">
              <div className="flex justify-end items-center">
                <div
                  className={`cursor-pointer flex items-center gap-1`}
                  onClick={() => {
                    setSortModal(true);
                  }}
                >
                  <span className={`${TYPO.TYPO7_MD} text-gray50`}>
                    {sortIndex === 0
                      ? "총 수익 높은 순"
                      : sortIndex === 1
                      ? "일간 수익 높은 순"
                      : "이름 순"}
                  </span>
                  <ArrowDown />
                </div>
              </div>
              <div className="flex items-center gap-1 flex-wrap justify-end">
                <GrayToggleButton
                  isToggled={priceToggle}
                  onClick={() => {
                    setPriceToggle(!priceToggle);
                  }}
                  leftText={router.locale === "ko" ? "현재가" : "Last Price"}
                  rightText={router.locale === "ko" ? "평가금" : "Market Value"}
                />
                <GrayToggleButton
                  isToggled={currToggle}
                  onClick={() => {
                    setCurrToggle(!currToggle);
                  }}
                  leftText={
                    router.locale === "ko" ? "$" : "Currency Denomination"
                  }
                  rightText={router.locale === "ko" ? "원" : "USD"}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {sortedItems.map((data: any, i: number) => (
                <ListTemp data={data} key={i} />
              ))}
            </div>
          </>
        )}

        <div className="py-2">
          <div
            className="py-2 px-6 flex items-center gap-3 cursor-pointer"
            onClick={() => {
              router.push("/kb/order/list");
            }}
          >
            <span className={`grow ${TYPO.TYPO5_B} text-gray80`}>주문내역</span>
            <Forward fill="gray40" height={16} width={16} />
          </div>
        </div>
        {sortModal && (
          <MyStockSortModal
            isModalOpen={sortModal}
            setModalOpen={setSortModal}
            selectIndex={setSortIndex}
            index={sortIndex}
          />
        )}
        {cantGoDetail && (
          <CantGoDetailModal
            setModalOpen={setCantGoDetail}
            isModalOpen={cantGoDetail}
          />
        )}
      </div>
      {valuationData?.length === 0 && (
        <div className="bg-white w-full  flex items-center justify-center flex-col gap-4 ">
          <DividerLine size="lg" />
          <HighTotalScoreStocks />
          <MyStockService isLoginUser={isLoginUser} />
          <DividerLine size="lg" />
          <StrateRanking />
        </div>
      )}
    </div>
  );
}
const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.02911 7.6375L9.70411 3.9625C9.82911 3.8375 9.97494 3.77708 10.1416 3.78125C10.3083 3.78542 10.4541 3.85 10.5791 3.975C10.7041 4.1 10.7666 4.24583 10.7666 4.4125C10.7666 4.57917 10.7041 4.725 10.5791 4.85L6.74161 8.7C6.64161 8.8 6.52911 8.875 6.40411 8.925C6.27911 8.975 6.15411 9 6.02911 9C5.90411 9 5.77911 8.975 5.65411 8.925C5.52911 8.875 5.41661 8.8 5.31661 8.7L1.46661 4.85C1.34161 4.725 1.28119 4.57708 1.28536 4.40625C1.28952 4.23542 1.35411 4.0875 1.47911 3.9625C1.60411 3.8375 1.74994 3.775 1.91661 3.775C2.08327 3.775 2.22911 3.8375 2.35411 3.9625L6.02911 7.6375Z"
        fill="#8194A7"
      />
    </svg>
  );
};
export default MyStockLists;
