import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";
import Button from "./buttons/buttons";

interface props {
  clickHandler?: any;
  title?: string;
  edit?: boolean;
  icon1?: string;
  icon1Handler?: any;
  icon2?: string;
  icon2Handler?: any;
  editHandler?: any;
  editText?: string;
  back?: boolean;
  bgColor?: string;
}

function TopHeader({
  clickHandler,
  title,
  edit,
  icon1,
  icon2,
  icon1Handler,
  icon2Handler,
  editHandler,
  editText,
  back = true,
  bgColor,
}: props) {
  const router = useRouter();
  return (
    <div
      className={`h-14 py-[3px] relative w-full ${
        bgColor ? `bg-[${bgColor}]` : "bg-white"
      }  flex justify-center items-center `}
    >
      {back && (
        <div
          className="back absolute top-[50%] left-[4px] translate-y-[-50%] p-3 cursor-pointer"
          onClick={() => {
            clickHandler();
          }}
        >
          <Image
            src={"/images/icons/back.svg"}
            alt="arrow"
            width={24}
            height={24}
          />
        </div>
      )}

      {title && <span className={`${TYPO.TYPO6_SB} text-gray80`}>{title}</span>}

      {edit && (
        <div className="cursor-pointer absolute right-[12px] top-[50%] translate-y-[-50%] justify-center items-center gap-1 inline-flex">
          <Button
            clickHandler={() => {
              editHandler();
            }}
            text={
              editText ? editText : router.locale === "ko" ? "편집" : "Edit"
            }
            size="md"
            type="text_gray"
          />
        </div>
      )}
      {(icon1 || icon2) && (
        <div className="gap-4 flex justify-center items-center">
          {icon1 && (
            <div
              onClick={() => {
                icon1Handler();
              }}
            >
              <Image src={`${icon1}`} alt="icon" width={30} height={30} />
            </div>
          )}
          {icon2 && (
            <div
              onClick={() => {
                icon2Handler();
              }}
            >
              <Image src={`${icon2}`} alt="icon" width={30} height={30} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TopHeader;
