import Button from "components/common/buttons/buttons";
import TopHeader from "components/common/header";
import { Forward } from "components/common/Icons";
import BottomModal from "components/common/Modal/BottomModal";
import CancelAccidentAccountDescModal from "components/modals/KB/CancelAccidentAccountDescModal";
import {
  formatAccountNo,
  formatDisplayAccountNo,
} from "components/templates/common/Account/util";
import { useKBGlobalState } from "contexts/KBContext";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TYPO } from "styles/Typo";

const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_17205_15784)">
        <path
          d="M9.98223 11.7494L5.56282 7.32995C5.31238 7.07952 5.18717 6.78489 5.18717 6.44607C5.18717 6.10725 5.31238 5.81262 5.56282 5.56218C5.81325 5.31175 6.10788 5.18653 6.4467 5.18653C6.78552 5.18653 7.08015 5.31175 7.33058 5.56218L11.75 9.9816L16.1694 5.56218C16.4199 5.31175 16.7145 5.18653 17.0533 5.18653C17.3921 5.18653 17.6868 5.31175 17.9372 5.56218C18.1876 5.81262 18.3128 6.10725 18.3128 6.44607C18.3128 6.78489 18.1876 7.07952 17.9372 7.32995L13.5178 11.7494L17.9372 16.1688C18.1876 16.4192 18.3128 16.7138 18.3128 17.0527C18.3128 17.3915 18.1876 17.6861 17.9372 17.9366C17.6868 18.187 17.3921 18.3122 17.0533 18.3122C16.7145 18.3122 16.4199 18.187 16.1694 17.9366L11.75 13.5171L7.33058 17.9366C7.08015 18.187 6.78552 18.3122 6.4467 18.3122C6.10788 18.3122 5.81325 18.187 5.56282 17.9366C5.31238 17.6861 5.18716 17.3915 5.18717 17.0527C5.18716 16.7138 5.31238 16.4192 5.56282 16.1688L9.98223 11.7494Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17205_15784">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="10" fill="#FA4D56" />
      <path
        d="M10.4077 7.83666C10.3064 6.89107 11.0474 6.06641 11.9984 6.06641C12.9494 6.06641 13.6905 6.89107 13.5892 7.83666L12.9835 13.4898C12.9295 13.9932 12.5047 14.3749 11.9984 14.3749C11.4922 14.3749 11.0673 13.9932 11.0134 13.4898L10.4077 7.83666Z"
        fill="white"
      />
      <path
        d="M10.8115 16.7487C10.8115 16.0932 11.3429 15.5618 11.9984 15.5618C12.654 15.5618 13.1854 16.0932 13.1854 16.7487C13.1854 17.4043 12.654 17.9357 11.9984 17.9357C11.3429 17.9357 10.8115 17.4043 10.8115 16.7487Z"
        fill="white"
      />
    </svg>
  );
};

// 모달 외부 클릭을 감지하는 이벤트 핸들러
// const handleClickOutside = (event: MouseEvent) => {
//   if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
//     setKeypadModalOpen(false);
//     const ke = document.getElementById("vkpad_vkpad_value1_num");
//     if (ke) ke.style.display = "none";
//   }
// };
// useEffect(() => {
//   document.addEventListener("mousedown", handleClickOutside);

//   return () => {
//     document.removeEventListener("mousedown", handleClickOutside);
//   };
// }, [handleClickOutside]);
// const modalRef = useRef<HTMLDivElement>(null);

const Screen = ({ setKeypadModalOpen, passwordError, currentAccount }: any) => {
  console.log("passwordRander");
  const [keypadLength, setKeypadLength] = useState(0);
  const [keypadLoaded, setKeypadLoaded] = useState(false);

  useEffect(() => {
    // 기존 console.log를 저장
    const originalConsoleLog = console.log;

    // console.log 재정의
    console.log = (...args) => {
      // 원래 로그 출력
      originalConsoleLog(...args);
      // 로그 메시지 감지 및 처리
      const logMessage = args.join(" ");

      const sanitizedLogMessage = logMessage.replace(/\s+/g, " ").trim();
      if (sanitizedLogMessage.startsWith("Key Press! length :")) {
        const match = logMessage.match(/Key Press!\s*length\s*:\s*(\d+)/);
        if (match) {
          const length = Number(match[1]);
          setKeypadLength(length);
        }
      }
    };

    // 컴포넌트가 언마운트될 때 원래 console.log 복원
    return () => {
      console.log = originalConsoleLog;
    };
  }, []);

  console.log("keypadLength?", keypadLength);

  useEffect(() => {
    // 스크립트 동적 로드
    // const loadScript = (src: any) => {
    //   return new Promise((resolve, reject) => {
    //     const script = document.createElement("script");
    //     script.src = src;
    //     script.type = "text/javascript";
    //     script.onload = resolve;
    //     script.onerror = reject;
    //     document.body.appendChild(script);
    //   });
    // };

    const loadScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          console.log("already loaded");
          // 이미 스크립트가 로드된 경우
          resolve();
          return;
        }
        console.log("loading script");
        const script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.onload = () => resolve();
        script.onerror = () =>
          reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript(
          "https://baasviewapi.kbsec.com/plugins/jquery-1.11.3.min.js"
        );
        await loadScript(
          "https://baasviewapi.kbsec.com/plugins/kings/vKeypad.min.js"
        );
        await loadScript(
          "https://baasviewapi.kbsec.com/plugins/kings/kjscrypto.min.js"
        );
        await loadScript(
          "https://baasviewapi.kbsec.com/plugins/kings/kjscrypto_contrib.min.js"
        );
        await loadScript(
          "https://baasviewapi.kbsec.com/plugins/kings/vKeypadAPI.js"
        );

        // setTimeout(() => {
        //   const ke = document.getElementById("vkpad_vkpad_value1_num");
        //   if (ke) ke.style.display = "inline";
        //   console.log("ke", ke);
        // }, 1000);

        (window as any).vKeypadAPI.isClose = false;
        (window as any).vKeypadAPI.isProtectBtn = false;

        // 가상키패드 초기화
        (window as any).vKeypadAPI.initVKPad({
          onloadCallback: () => {
            const ke = document.getElementById("vkpad_vkpad_value1_num");
            if (ke) ke.style.display = "inline";
            setKeypadLoaded(true);
            console.log("initKeypad");

            // if (ke) {
            //   // console.log("ke", ke);
            //   ke.click();
            //   ke.focus();
            // }

            // ke의 하위에 있는 첫번째 a태그
            const aTag = ke?.querySelector("a");
            // // aTag의 하위 image태그의 3,4,5번째 자식을 가져옴
            const img1 = aTag?.querySelectorAll("img")[0];
            // const img2 = aTag?.querySelectorAll("img")[3];
            // const img3 = aTag?.querySelectorAll("img")[4];
            // // 각 이미지를 숨김
            // if (img1) img1.style.display = "11px";
            // if (img2) img2.style.left = "40px";
            // if (img3) img3.style.left = "330px";

            const capture = document.getElementById("vkpad_vkpad_value1_num");
          },
          showCallback: () => console.log("--showCallback--"),
          hideCallback: () => console.log("--hideCallback--"),
          // KeyPressCallback: (data: any, a: any) => {
          //   console.log("data", data);
          //   console.log("a", a);
          // },

          doneCallback: (data: any) => {
            console.log("--doneCallback--");
            console.log(`result : [${data}]`);
            const ke = document.getElementById("vkpad_vkpad_value1_num");

            // if (ke) ke.style.display = "inline";
            //inputRef 값 초기화
            if (inputRef.current) inputRef.current.value = "";

            window.parent.postMessage({ encryptedPassword: data }, "*");
          },
        });
      } catch (error) {
        console.error("Error loading scripts", error);
      }
    };

    loadScripts();
  }, []);
  const inputRef = useRef<HTMLInputElement>(null);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    // dom이 로드되었을 때 사용자 화면 width 체크
    setScreenWidth(window.innerWidth);
  }, []);

  const SpotComponent = (isActive: boolean) => {
    return (
      <div
        className={`rounded-full bg-gray2 w-[18px] h-[18px] ${
          isActive ? "bg-gray50" : "bg-gray20"
        }`}
      ></div>
    );
  };
  return (
    <div
      className={`z-[500] fixed top-0 left-0 w-full  h-full  justify-center bg-white`}
    >
      <div className="pl-[10px] py-[6px]">
        <div
          className="p-[10px] cursor-pointer"
          onClick={() => {
            setKeypadModalOpen(false);
            const ke = document.getElementById("vkpad_vkpad_value1_num");
            if (ke) ke.style.display = "none";
          }}
        >
          <XIcon />
        </div>
      </div>
      <div
        className="w-screen flex flex-col justify-center items-center relative"
        style={{ height: `calc(100vh - ${screenWidth / 1.32 + 20}px)` }}
      >
        {/** 비율 : 가로/세로 1.32 */}
        <div
          style={
            {
              // visibility: keypadLoaded ? "visible" : "hidden",
            }
          }
          className="h-full flex  items-center justify-center absolute top-0 left-0 w-full z-[10] bg-transparent "
        >
          <div>
            {passwordError > 0 ? (
              <div
                className={`absolute left-0 w-full text-center flex flex-col ${TYPO.TYPO4_SB}`}
                style={{ top: "calc(50% - 80px)" }}
              >
                <span className="text-gray80">비밀번호가 맞지 않아요</span>
                <div>
                  <span className="text-gray80">다시 눌러주세요</span>
                  <span className="text-red40">{passwordError}/5</span>
                </div>
              </div>
            ) : (
              <div
                className={`absolute left-0 w-full text-center flex flex-col gap-3 `}
                style={{ top: "calc(50% - 100px)" }}
              >
                <span className={` ${TYPO.TYPO7_MD} text-gray50`}>
                  {`KB종합위탁계좌 ${formatDisplayAccountNo(currentAccount)}`}
                </span>
                <span className={` ${TYPO.TYPO4_SB} text-gray80`}>
                  계좌 비밀번호를 눌러주세요
                </span>
              </div>
            )}
          </div>
          {/* <div>{keypadLength}</div> */}
          <div
            className="w-full items-center justify-center absolute top-0 left-0 h-full "
            style={{ top: "calc(50% - 10px)" }}
          >
            <div className="flex justify-center items-center gap-3">
              {Array.from({ length: 4 }, (_, i) => {
                return (
                  <div key={i} className="flex w-fit h-fit">
                    {SpotComponent(i < keypadLength)}
                  </div>
                );
              })}
            </div>
          </div>

          <form className="h-full w-full">
            <table className="w-full h-full">
              <tbody>
                <tr>
                  <td>
                    <input
                      ref={inputRef}
                      className="opacity-0 kb-password-inputer input w-full text-gray50 !text-[2rem] h-full  bg-transparent tracking-tighter"
                      style={{
                        paddingLeft: "calc(50% - 80px)",
                        // visibility: "hidden",
                      }}
                      name="vkpad_value1"
                      {...({ knc_etoe: "N" } as any)} // `knc_etoe` 속성 허용
                      maxLength={4}
                      placeholder="••••"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        {/* <div className="absolute top-0 left-0 h-full w-full bg-transparent z-[1]">
          <input
            ref={inputRef}
            className="kb-password-inputer input w-full text-gray50 !text-[2rem] h-full   tracking-tighter bg-transparent"
            type="password"
            maxLength={4}
            placeholder="••••"
            style={{
              paddingLeft: "calc(50% - 80px)",
            }}
          />
        </div> */}
      </div>
    </div>
  );
};
export const MemoScreen = React.memo(Screen);

export const PasswordFailOverAndAccident = ({
  setPasswordFailOverModal,
  isMobile,
  addHandler,
}: any) => {
  const [descModalOpen, setDescModalOpen] = useState(false);
  return (
    <div
      className={`z-[500] fixed top-0 left-0 w-full  h-full  justify-center bg-white`}
    >
      <TopHeader
        clickHandler={() => {
          setPasswordFailOverModal(false);
        }}
      />
      <div className="px-6 pt-6 mb-4">
        <span className={`${TYPO.TYPO3_SB} text-gray90 whitespace-pre-line`}>
          {"비밀번호 5회 오류로\n 계좌가 정지됐어요"}
        </span>
      </div>
      <div
        className="py-2 cursor-pointer"
        onClick={() => {
          setDescModalOpen(true);
        }}
      >
        <div className="flex justify-between items-center w-full px-6 py-2">
          <span className={``}>비밀번호 재설정 방법</span>
          <Forward width={16} height={16} fill={"gray40"} />
        </div>
      </div>
      <div>
        <div
          className={
            isMobile
              ? ` z-[99] bg-white w-full px-5 pt-4 pb-[30px] fixed bottom-0 left-0 !font-semibold flex flex-col gap-4 `
              : `z-[99] bg-white w-[800px] px-5 pt-4 pb-[30px] fixed bottom-0 left-[50%] translate-x-[-50%] !font-semibold flex flex-col gap-4 `
          }
        >
          <Button
            type="primary"
            clickHandler={() => {
              if (addHandler) addHandler();
              else setPasswordFailOverModal(false);
            }}
            size="lg"
            text={"확인"}
            width="w-full"
          />
        </div>
      </div>
      {descModalOpen && (
        <CancelAccidentAccountDescModal
          isModalOpen={descModalOpen}
          setModalOpen={setDescModalOpen}
        />
      )}
    </div>
  );
};

export const useKbPassword = (isMobile: boolean) => {
  const {
    encryptedPasswords,
    isLoading,
    currentAccount,
    isAccountLoading,
    passwordError,
  } = useKBGlobalState();
  const encryptedPassword =
    encryptedPasswords && currentAccount
      ? encryptedPasswords[currentAccount?.accountNo]
      : null;

  const inPasswordMode = !encryptedPassword;
  const [kepadModalOpen, setKeypadModalOpen] = useState(true);
  const [passwordFailOverModal, setPasswordFailOverModal] = useState(
    passwordError ? passwordError >= 5 : false
  );

  useEffect(() => {
    if (passwordError >= 5) {
      setPasswordFailOverModal(true);
    }
  }, [passwordError]);
  useEffect(() => {
    if (encryptedPassword) {
      const ke = document.getElementById("vkpad_vkpad_value1_num");
      if (ke) ke.style.display = "none";

      if (passwordError > 0) {
      } else {
        if (ke) ke.style.display = "none";
      }
    }
  }, [encryptedPassword]);

  const PasswordScreen = useCallback(() => {
    if (!currentAccount) return <div></div>;
    if (isAccountLoading || (isLoading && currentAccount)) return <div></div>;

    if (!isMobile) {
      return (
        <div className="bg-white min-h-screen flex flex-col justify-center items-center">
          <span className={`${TYPO.TYPO5_1_MD}`}>
            {"모바일 환경에서 서비스를 이용할 수 있습니다"}
          </span>
        </div>
      );
    }

    if (!kepadModalOpen) return <></>;

    if (passwordFailOverModal) {
      return (
        <div>
          <PasswordFailOverAndAccident
            setPasswordFailOverModal={setPasswordFailOverModal}
            isMobile={isMobile}
          />
        </div>
      );
    }

    return (
      <MemoScreen
        isLoginUser={true}
        router={null}
        isAccountLoading={false}
        currentAccount={currentAccount?.accountNo}
        setKeypadModalOpen={setKeypadModalOpen}
        passwordError={passwordError}
      />
    );
  }, [
    isAccountLoading,
    isLoading,
    currentAccount,
    kepadModalOpen,
    passwordError,
  ]);

  return [
    encryptedPassword,
    PasswordScreen,
    inPasswordMode,
    isLoading,
  ] as const;
};
