import Button from "components/common/buttons/buttons";
import Image from "next/image";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

function KbConnectAd({}: {}) {
  const router = useRouter();
  const ctaClickHandler = () => {
    // if(!isLoginUser){
    //     router.push("/login");
    // }
    // else{
    // }
    router.push("/kb/welcome");
  };
  return (
    <div className="p-5 w-full">
      <div className="bg-gray2 rounded-[24px] flex flex-col pt-3 w-full gap-4">
        <div className="flex gap-4 items-center px-5 py-2">
          <div className="flex items-center justify-center">
            <Image
              src="/images/kblogo.png"
              width={40}
              height={40}
              alt="kb_logo"
            />
          </div>
          <div className="flex flex-col">
            <span className={`${TYPO.TYPO7_RG} text-gray60`}>
              내 KB증권 계좌 그대로
            </span>
            <span
              className={`text-[22px] font-semibold font-['Pretendard'] leading-[30.80px] text-gray90`}
            >
              리스크웨더에서
              <br />더 간편한 거래하기
            </span>
          </div>
        </div>
        <div className="px-5 pb-5 w-full">
          <Button
            type="primary"
            size="lg"
            text="계좌 연결하기"
            width="w-full"
            clickHandler={() => {
              ctaClickHandler();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default KbConnectAd;
