import { useRouter } from "next/router";

function GrayToggleButton({ isToggled, onClick, leftText, rightText }: any) {
  return (
    <div
      className="w-fit h-[26px] p-1 relative bg-gray-100 rounded-lg cursor-pointer whitespace-nowrap flex items-center"
      onClick={onClick}
    >
      <div
        className={`${
          !isToggled ? "text-gray70 bg-white" : "text-gray50"
        } h-full px-1 rounded-[5px] justify-center items-center flex text-center`}
        style={
          !isToggled
            ? {
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
                WebkitBoxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
              }
            : {}
        }
      >
        <span className="text-center text-xs font-normal leading-3 ">
          {leftText}
        </span>
      </div>
      <div
        className={` ${
          isToggled ? "text-gray70 bg-white" : "text-gray50"
        } h-full px-1  rounded-[5px] justify-center items-center flex text-center`}
        style={
          isToggled
            ? {
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
                WebkitBoxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
              }
            : {}
        }
      >
        <span className="text-center text-xs font-normal leading-3">
          {rightText}
        </span>
      </div>
    </div>
  );
}

export default GrayToggleButton;
