import useSWR from "swr";
import { fetcher } from "./fetcher";
import { STRATEGY_TYPE } from "utils/constant/STATUS";
import * as Sentry from '@sentry/nextjs';
import { useEffect } from "react";

const useStrategyData = (code: STRATEGY_TYPE): [any, boolean] => {
    const { data, isValidating } = useSWR(
        `/api/strategy/info?code=${code}`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;

    // useEffect(() => {
    //     if (!isValidating) {
    //         if (!data?.totalBaseDT || !data?.kospiLogReturns || !data?.strategyLogReturns || !data?.strategyRisk || data?.strategyRisk === null) {
    //             // Sentry에 에러 전송
    //             Sentry.captureMessage(`STRATEGY DATA ERROR`, {
    //                 level: 'error',
    //                 tags: {
    //                     errorType: 'STRATEGY INFO', // 나중에 필터링할 태그
    //                 },
    //                 extra: { data: data, code: code },
    //             });
    //         }
    //     }
    // }, [isValidating]);


    return [data, isValid];
}
export const useStrategyMainData = (): [any, boolean] => {
    const { data, isValidating } = useSWR(
        `/api/strategy/main`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;

    return [data, isValid];
}
export const useStrategyAssets = (code: STRATEGY_TYPE, lastday: any, startDate?: any, endDate?: any, iscurrent?: boolean): [any, boolean] => {
    const { data, isValidating } = useSWR(
        `/api/strategy/assets?code=${code}&startdate=${startDate}&enddate=${endDate ? endDate : ""}&lastday=${lastday}&iscurrent=${iscurrent ? iscurrent : undefined}`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    // useEffect(() => {
    //     if (!isValidating) {

    //         if (!data?.list || !data?.month) {
    //             // Sentry에 에러 전송
    //             Sentry.captureMessage(`STRATEGY ${code} ASSETS DATA ERROR `, {
    //                 level: 'error',
    //                 tags: {
    //                     errorType: 'STRATEGY ASSETS', // 나중에 필터링할 태그
    //                 },
    //                 extra: { data: data, code: code },
    //             });
    //         }
    //     }
    // }, [isValidating]);

    const isValid = !data && isValidating;

    return [data, isValid];
}

export const useHighStrategyAssets = (): any => {
    const { data, isValidating, error } = useSWR(
        `/api/strategy/high`,
        fetcher, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    }
    );
    const isValid = !data && isValidating;
    const BASE_DT = data?.BASE_DT
    const list = data?.list

    return [list, BASE_DT, isValid, error];
}
export default useStrategyData;