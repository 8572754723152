import BottomFloatingModal from "components/common/Modal/BottomFloatingModal";
import useModalClickOutside from "utils/useModalClickOutside";
import { TYPO } from "styles/Typo";
import Button from "components/common/buttons/buttons";
import { useRouter } from "next/router";
import { useTradeState } from "contexts/KBTradeContext";
import Image from "next/image";

function CancelAccidentAccountDescModal({
  setModalOpen,
  isModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: any;
}) {
  const modalRef = useModalClickOutside(setModalOpen);

  return (
    <BottomFloatingModal isOpen={isModalOpen} modalRef={modalRef}>
      <div className="flex flex-col gap-4 w-full py-4">
        <div className="px-5">
          <div className="p-4 flex flex-col justify-center items-center gap-2">
            <Image
              src="/images/banks/mable.svg"
              alt="mable"
              width={48}
              height={48}
            />
            <span className={`${TYPO.TYPO7_MD} text-gray80`}>
              KB M-able 앱을 실행해주세요
            </span>
          </div>
        </div>
        <div className="py-2 flex flex-col gap-2">
          <div className="px-5 py-2 flex gap-3 items-center">
            <div className="rounded-[6px] bg-gray10 flex items-center justify-center w-5 h-5">
              <span
                className={`text-gray50 text-[15px] font-semibold font-['Pretendard'] leading-[15px] `}
              >
                1
              </span>
            </div>
            <span className={`${TYPO.TYPO5_1_MD} text-gray80`}>
              {`메뉴>고객서비스>ID/계좌/비밀번호`}
            </span>
          </div>
          <div className="px-5 py-2 flex gap-3 items-center">
            <div className="rounded-[6px] bg-gray10 flex items-center justify-center w-5 h-5">
              <span
                className={`text-gray50 text-[15px] font-semibold font-['Pretendard'] leading-[15px] `}
              >
                2
              </span>
            </div>
            <span className={`${TYPO.TYPO5_1_MD} text-gray80`}>
              {`계좌조회/관리 메뉴 접속`}
            </span>
          </div>
          <div className="px-5 py-2 flex gap-3 items-center">
            <div className="rounded-[6px] bg-gray10 flex items-center justify-center w-5 h-5">
              <span
                className={`text-gray50 text-[15px] font-semibold font-['Pretendard'] leading-[15px] `}
              >
                3
              </span>
            </div>
            <span className={`${TYPO.TYPO5_1_MD} text-gray80`}>
              {`‘계좌비밀번호 차단해제’ 진행`}
            </span>
          </div>
        </div>
      </div>
    </BottomFloatingModal>
  );
}

export default CancelAccidentAccountDescModal;
