import DividerLine from "components/common/divider/line";
import List from "components/common/List/list";
import {
  StrategySkeletonDesktop,
  StrategySkeletonMobile,
} from "components/skeleton/main";
import Image from "next/image";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { TYPO } from "styles/Typo";
import { useStrategyMainData } from "utils/clientAPIs/stragey";
import { toDetailPage } from "utils/navigateToDetailPage";
import { Forward } from "components/common/Icons";

function Strategy({ isMobile }: { isMobile: boolean }) {
  const router = useRouter();
  const { t } = useTranslation("main");
  const [data, isValid] = useStrategyMainData();
  const contents = [
    // {
    //   title: router.locale === "ko" ? "리스크웨더 Pro" : "RiskWeather Pro",
    //   desc:
    //     router.locale === "ko"
    //       ? "AI가 추천하는 잃지 않는 투자 전략"
    //       : "AI-Powered Strategies to Never Lose",
    //   count: data?.rpn?.length,
    //   stockList: data ? [...data?.rpn?.slice(0, 3)] : [],
    //   onclick: () => {
    //     router.push("/strategy/rpn?type=holdings");
    //   },
    // },
    {
      title:
        router.locale === "ko"
          ? "뜨는 산업에 올라타기"
          : "Riding emerging industries",
      desc:
        router.locale === "ko"
          ? "상승세 강한 산업의 대장주"
          : "Leading stocks in high-momentum sectors",
      count: data?.ri5?.length,
      stockList: data ? [...data?.ri5?.slice(0, 3)] : [],
      onclick: () => {
        router.push("/strategy/ri5?type=holdings");
      },
    },
    {
      title:
        router.locale === "ko"
          ? "믿을만한 대형주"
          : "Reliable Blue-Chip Stocks",
      desc:
        router.locale === "ko"
          ? "계속 성장할 시가총액 상위 기업"
          : "Companies with Market-Cap Expected to Keep Growing",
      count: data?.km7?.length,
      stockList: data ? [...data?.km7?.slice(0, 3)] : [],
      onclick: () => {
        router.push("/strategy/km7?type=holdings");
      },
    },
    // {
    //   title: router.locale === "ko" ? "rs3" : "rs3",
    //   desc: router.locale === "ko" ? "rs3" : "rs3",
    //   count: data?.rs3?.length,
    //   stockList: data ? [...data?.rs3?.slice(0, 3)] : [],
    //   onclick: () => {
    //     router.push("/strategy/rs3?type=holdings");
    //   },
    // },
    {
      title: router.locale === "ko" ? "균형의 날개 펼치기" : "Wings of Balance",
      desc:
        router.locale === "ko"
          ? "재무적인 안정성이 높은 회사"
          : "High investment attractiveness based on debt management",
      count: data?.fs7?.length,
      stockList: data ? [...data?.fs7?.slice(0, 3)] : [],
      onclick: () => {
        router.push("/strategy/fs7?type=holdings");
      },
    },
  ].filter((item) => item?.stockList?.length > 0);

  //데스크탑 캐러샐
  const [move, setMove] = useState(0);
  const contentsDiv = useRef<HTMLDivElement>(null);
  const entireWidth = contentsDiv.current?.clientWidth as number;
  const fixWidth = 800;
  const movePX = 450;
  const moveRight = () => {
    if (entireWidth <= movePX * move) {
      return;
    }
    setMove((prev) => prev + 1);
  };
  const moveLeft = () => {
    if (move != 0) {
      setMove((prev) => prev - 1);
    }
  };

  return (
    <div className=" bg-gray2">
      {isValid && (
        <>
          {isMobile ? <StrategySkeletonMobile /> : <StrategySkeletonDesktop />}
        </>
      )}
      {!isValid && (
        <>
          <div className="pt-[30px] px-6">
            <span className={`${TYPO.TYPO4_SB} text-gray80`}>
              {t("investSmarterEarnBigger")}
            </span>
          </div>
          {isMobile ? (
            <div className="overflow-x-auto scroll">
              <div className="flex  gap-4 p-6 w-fit items-stretch">
                {contents.map((content, i) => (
                  <div
                    className=" bg-white rounded-[30px] w-[295px]  shrink-0 overflow-hidden flex flex-col gap-2"
                    key={i}
                    onClick={(event: any) => {
                      window.dataLayer.push({
                        event: "cMainStrat",
                      });
                      content?.onclick();
                    }}
                  >
                    <div className="flex flex-col px-6 pt-6 gap-1">
                      <span className={`${TYPO.TYPO5_SB} text-gray70`}>
                        {content.title}
                      </span>
                      <span
                        className={`${TYPO.TYPO7_RG} text-gray60 break-keep`}
                      >
                        {content.desc}
                      </span>
                    </div>
                    <div className="grow py-2 flex flex-col gap-2 justify-center">
                      {content.stockList.map((asset, i) => (
                        <div
                          className="cursor-pointer"
                          key={i}
                          onClick={(event: any) => {
                            event.stopPropagation(); // 부모로 이벤트 전파 방지
                            window.dataLayer.push({
                              event: "cMainStratList",
                            });
                            toDetailPage(router, asset.ITEM_CD_DL);
                          }}
                        >
                          <List
                            HR_ITEM_NM={""}
                            item={
                              router.locale === "ko"
                                ? asset?.ITEM_KR_NM
                                : asset?.ITEM_ENG_NM
                            }
                            src={
                              `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
                              "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
                            }
                            rightIcon={() => (
                              <span
                                className={`${TYPO.TYPO5_1_SB} text-blue40`}
                              >
                                {asset?.TOTAL_PER}
                                {router.locale === "ko" ? "점" : "p"}
                              </span>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      className={`w-full  flex flex-col `}
                      onClick={(event: any) => {
                        event.stopPropagation(); // 부모로 이벤트 전파 방지
                        window.dataLayer.push({
                          event: "cMainStrat",
                        });
                        content?.onclick();
                      }}
                    >
                      <DividerLine size="sm" />
                      <div
                        className={`cursor-pointer px-[18px] py-3.5 w-full text-center flex justify-center items-center gap-1`}
                      >
                        <span className={`${TYPO.TYPO6_MD} text-gray60`}>
                          {`${
                            content.count > 3
                              ? router.locale === "ko"
                                ? `${content.count - 3}개 주식 더 보기`
                                : `View ${content.count - 3} more stocks`
                              : router.locale === "ko"
                              ? `더 보기`
                              : "View Mmore stocks"
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="relative ">
              <div className="overflow-hidden">
                <div
                  ref={contentsDiv}
                  className={`flex gap-4 p-6 w-fit ${
                    move === 0 ? "pl-[1.25rem]" : ""
                  } w-fit flex justify-start   overflow-x-auto scroll !items-stretch `}
                  style={
                    contents?.length < 3
                      ? {}
                      : {
                          transition: "all .2s ease .2s",
                          transform: `translate(${
                            entireWidth - (movePX * (move - 1) + fixWidth) <=
                            movePX
                              ? -movePX * (move - 1) -
                                (entireWidth -
                                  (movePX * (move - 1) + fixWidth) +
                                  20)
                              : -movePX * move
                          }px)`,
                        }
                  }
                >
                  {contents.map((content, i) => (
                    <div
                      className=" bg-white rounded-[30px] w-[295px]  shrink-0 overflow-hidden flex flex-col gap-2 cursor-pointer"
                      key={i}
                      onClick={(event: any) => {
                        window.dataLayer.push({
                          event: "cMainStrat",
                        });
                        content?.onclick();
                      }}
                    >
                      <div className="flex flex-col px-6 pt-6 gap-1">
                        <span className={`${TYPO.TYPO5_SB} text-gray70`}>
                          {content.title}
                        </span>
                        <span
                          className={`${TYPO.TYPO7_RG} text-gray60 break-keep`}
                        >
                          {content.desc}
                        </span>
                      </div>
                      <div className="grow py-2 flex flex-col gap-2 justify-center">
                        {content.stockList.map((asset, i) => (
                          <div
                            className="cursor-pointer"
                            key={i}
                            onClick={(event: any) => {
                              event.stopPropagation(); // 부모로 이벤트 전파 방지
                              window.dataLayer.push({
                                event: "cMainStratList",
                              });
                              toDetailPage(router, asset.ITEM_CD_DL);
                            }}
                          >
                            <List
                              HR_ITEM_NM={""}
                              item={
                                router.locale === "ko"
                                  ? asset?.ITEM_KR_NM
                                  : asset?.ITEM_ENG_NM
                              }
                              src={
                                `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
                                "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
                              }
                              rightIcon={() => (
                                <span
                                  className={`${TYPO.TYPO5_1_SB} text-blue40`}
                                >
                                  {asset?.TOTAL_PER}
                                  {router.locale === "ko" ? "점" : "P"}
                                </span>
                              )}
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        className={`w-full  flex flex-col `}
                        onClick={(event: any) => {
                          event.stopPropagation(); // 부모로 이벤트 전파 방지
                          window.dataLayer.push({
                            event: "cMainStrat",
                          });
                          content?.onclick();
                        }}
                      >
                        <DividerLine size="sm" />
                        <div
                          className={`cursor-pointer px-[18px] py-3.5 w-full text-center flex justify-center items-center gap-1`}
                        >
                          <span className={`${TYPO.TYPO6_MD} text-gray60`}>
                            {`${
                              content.count > 3
                                ? router.locale === "ko"
                                  ? `${content.count - 3}개 주식 더 보기`
                                  : `View ${content.count - 3} more stocks`
                                : router.locale === "ko"
                                ? `더 보기`
                                : "View Mmore stocks"
                            }`}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                onClick={() => {
                  moveLeft();
                }}
                className={`${
                  move === 0 && "hidden"
                }  cursor-pointer absolute z-[2] left-0 top-[50%] translate-y-[-50%] translate-x-[-50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
              >
                <Image
                  height={30}
                  width={30}
                  alt="left-btn"
                  src={"/images/icons/main/leftArrow.svg"}
                />
              </div>
              <div
                onClick={() => {
                  moveRight();
                }}
                className={`${
                  (movePX * move + fixWidth >= entireWidth ||
                    contents?.length < 3) &&
                  "hidden"
                }   cursor-pointer absolute z-[2] right-0 top-[50%] translate-y-[-50%] translate-x-[50%] w-[32px] h-[32px] p-2 bg-white rounded-[50px] shadow justify-start`}
              >
                <Image
                  height={30}
                  width={30}
                  alt="left-btn"
                  src={"/images/icons/main/rightArrow.svg"}
                />
              </div>
            </div>
          )}
          <div>
            <div className="py-2 ">
              <div
                className="w-full px-6 py-2 gap-3 items-center flex cursor-pointer"
                onClick={() => {
                  router.push("/strategy/rs3?type=holdings");
                  window.dataLayer.push({
                    event: "cMainStrat",
                  });
                }}
              >
                <div className="p-1 bg-white rounded-[16px]">
                  <SafeStrategyIcon />
                </div>
                <div className="flex flex-col grow">
                  <span className={`${TYPO.TYPO5_SB} text-gray70`}>
                    {router.locale === "ko"
                      ? "안정 성장주"
                      : "Stable growth stocks"}
                  </span>
                  <span className={`${TYPO.TYPO8_RG} text-gray60`}>
                    {router.locale === "ko"
                      ? "낮은 리스크로 꾸준한 성장이 기대되는 회사"
                      : "Consistent growth with low risk"}
                  </span>
                </div>
                <Forward width={16} height={16} fill={"gray30"} />
              </div>
            </div>
            <div
              className="w-full h-[30px]"
              style={{
                background: `linear-gradient(180deg, #F6F7F9 0%, #FFF 100%)`,
              }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
}

const SafeStrategyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3108 23.0632C20.7369 23.12 21.0362 23.5114 20.9794 23.9375C20.8167 25.1573 20.821 27.0336 21.3391 28.6716C21.8532 30.2969 22.8177 31.5531 24.5114 31.8919C24.9328 31.9762 25.2062 32.3862 25.1219 32.8077C25.0376 33.2292 24.6276 33.5025 24.2061 33.4182C21.749 32.9268 20.4651 31.0699 19.855 29.1411C19.249 27.225 19.2532 25.1062 19.4365 23.7318C19.4933 23.3057 19.8847 23.0064 20.3108 23.0632Z"
        fill="#25B16A"
      />
      <path
        d="M9.11654 14.4852C8.31171 11.2635 11.9198 10.4859 13.3034 10.8318C12.9575 9.44821 13.6768 5.89844 16.8984 6.70327C21.0509 7.74064 20.3581 13.967 20.0122 17.5989C16.3802 17.9448 10.1539 18.6376 9.11654 14.4852Z"
        fill="#25B16A"
      />
      <path
        d="M30.8843 20.7084C31.6891 23.93 28.081 24.7076 26.6974 24.3617C27.0433 25.7453 26.324 29.2951 23.1024 28.4903C18.9499 27.4529 19.6427 21.2266 19.9886 17.5947C23.6206 17.2488 29.8469 16.5559 30.8843 20.7084Z"
        fill="#25B16A"
      />
      <path
        d="M16.8992 28.4923C13.6776 29.2971 12.9 25.6891 13.2459 24.3055C11.8623 24.6514 8.3125 23.932 9.11733 20.7104C10.1547 16.5579 16.381 17.2508 20.013 17.5967C20.3589 21.2286 21.0517 27.4549 16.8992 28.4923Z"
        fill="#68C76F"
      />
      <path
        d="M23.1008 6.70167C26.3224 5.89684 27.1 9.50491 26.7541 10.8885C28.1377 10.5426 31.6875 11.2619 30.8827 14.4836C29.8453 18.636 23.619 17.9432 19.987 17.5973C19.6411 13.9654 18.9483 7.73904 23.1008 6.70167Z"
        fill="#68C76F"
      />
    </svg>
  );
};

export default Strategy;
