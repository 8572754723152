import axios from 'axios';

export const agreeTermsRequest = async (email: string) => {
    try {
        const re = await axios.post(`/api/kb/terms?email=${email}`, {}, {});
        const status = re.status;
        return status === 200;
    }
    catch (e) {
        return false
    }
}


// Helper function to check if a response is unauthorized (401)
const isUnauthorized = (error: any) => {
    console.log("isUnauthorized: ", error.response.status === 401);
    return error.response && error.response.status === 401;
};

const isForbidden = (error: any) => {
    console.log("isForbidden: ", error.response.status === 403);
    return error.response && error.response.status === 403;
}
const isAccidentAccount = (error: any) => {
    console.log("isAccidentAccount: ", error.response.status === 423);
    return error.response && error.response.status === 423;
}

const isNotTermsAgreed = (error: any) => {
    return error.response && error.response.status === 422;
}

const kbFetcherWithJWT = async (url: string, options: any = {}, email: string) => {
    try {

        const response = await axios({
            url,
            method: options.method || 'GET',
            headers: options.headers || {},
            data: options.body || null,
            withCredentials: true, // Include cookies in the request
        });
        return response.data;
    } catch (error: any) {

        if (isUnauthorized(error)) { // 토큰이 아예 없는 경우
            try {
                await axios.post(`/api/kb/auth?email=${email}`, {}, { withCredentials: true });
                const retryResponse = await axios({
                    url,
                    method: options.method || 'GET',
                    headers: options.headers || {},
                    data: options.body || null,
                    withCredentials: true,
                });
                return retryResponse.data;
            } catch (error) {
                // 약관 동의를 하지 않은 경우
                if (isNotTermsAgreed(error)) {
                    return { needTermsAgree: true };
                }
                console.error('Failed to access token:', error);
                throw error;
            }
        }
        else if (isForbidden(error)) {
            //리프레시 토큰을 통한 재발급 실행
            try {
                await axios.post('/api/kb/refresh', {}, { withCredentials: true });
                // Retry the original request after refreshing the token
                const retryResponse = await axios({
                    url,
                    method: options.method || 'GET',
                    headers: options.headers || {},
                    data: options.body || null,
                    withCredentials: true,
                });

                return retryResponse.data;
            } catch (refreshError) {
                console.error('Failed to refresh token:', refreshError);
                try {
                    await axios.post(`/api/kb/auth?email=${email}`, {}, { withCredentials: true });
                    // Retry the original request after refreshing the token
                    const retryResponse = await axios({
                        url,
                        method: options.method || 'GET',
                        headers: options.headers || {},
                        data: options.body || null,
                        withCredentials: true,
                    });
                    return retryResponse.data;
                } catch (e) {

                    throw refreshError;
                }
            }
        }

        else if (isAccidentAccount(error)) {
            return { accidentAccount: true };
        }

        console.error('Error during request:', error);
        throw error;
    }
};

export default kbFetcherWithJWT;
