;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"hBcPPq095_ybRF3DkOYCF"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;


if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: 'production',
        // Replay may only be enabled for the client-side
        integrations: [Sentry.replayIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        // ...

        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });

} else {
    // 개발 환경에서는 Sentry 초기화 생략 또는 environment를 development로 설정
    // Sentry.init({
    //     dsn: SENTRY_DSN,
    //     environment: 'development',
    //     // Replay may only be enabled for the client-side
    //     integrations: [Sentry.replayIntegration()],

    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for tracing.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0,

    //     // Capture Replay for 10% of all sessions,
    //     // plus for 100% of sessions with an error
    //     replaysSessionSampleRate: 0.1,
    //     replaysOnErrorSampleRate: 1.0,

    //     // ...

    //     // Note: if you want to override the automatic release value, do not set a
    //     // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    //     // that it will also get attached to your source maps
    // });

}