import BottomFloatingModal from "components/common/Modal/BottomFloatingModal";
import useModalClickOutside from "utils/useModalClickOutside";
import { TYPO } from "styles/Typo";
import Button from "components/common/buttons/buttons";

function CantGoDetailModal({
  setModalOpen,
  isModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: any;
}) {
  const modalRef = useModalClickOutside(setModalOpen);

  const clickHandler = () => {
    const a = document.createElement("a");
    a.href = "mablemini://";
    a.click();
  };

  return (
    <BottomFloatingModal isOpen={isModalOpen} modalRef={modalRef}>
      <div className="flex flex-col gap-4 w-full">
        <div className="pt-6 px-6 flex items-center justify-center">
          <Icon />
        </div>
        <div className="px-6 py-2 flex flex-col gap-2 justify-center items-center">
          <span className={`${TYPO.TYPO4_SB} text-gray90`}>
            서비스가 불가능한 종목이에요
          </span>
        </div>
        <div className="px-6 pb-6 flex flex-col items-center gap-2">
          <Button
            type="primary"
            clickHandler={() => {
              clickHandler();
              setModalOpen(false);
            }}
            size="xl"
            text="KB증권 앱에서 확인하기"
            width="w-full"
          />
          <Button
            type="tertiary"
            clickHandler={() => {
              setModalOpen(false);
            }}
            size="xl"
            text="닫기"
            width="w-full"
          />
        </div>
      </div>
    </BottomFloatingModal>
  );
}

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
    >
      <circle cx="24.5" cy="24" r="20" fill="#F99F01" />
      <path
        d="M21.8196 16.9829C21.6489 15.3896 22.8975 14 24.5 14C26.1025 14 27.3511 15.3896 27.1804 16.9829L26.1598 26.5085C26.0689 27.3568 25.3531 28 24.5 28C23.6469 28 22.9311 27.3568 22.8402 26.5085L21.8196 16.9829Z"
        fill="#3B4754"
      />
      <rect x="22.5" y="30" width="4" height="4" rx="2" fill="#3B4754" />
    </svg>
  );
};

export default CantGoDetailModal;
