import useModalClickOutside from "utils/useModalClickOutside";
import { TYPO } from "styles/Typo";
import Icon, { ICONS } from "components/common/Icons";
import BottomFloatingModal from "components/common/Modal/BottomFloatingModal";

function MyStockSortModal({
  setModalOpen,
  isModalOpen,
  index,
  selectIndex,
}: any) {
  const modalRef = useModalClickOutside(setModalOpen);

  return (
    <BottomFloatingModal
      isOpen={isModalOpen}
      modalRef={modalRef}
      containerClass="py-4 "
    >
      <div className={`flex flex-col gap-1 w-full px-6`}>
        <div
          className="flex items-center justify-between py-[6px] cursor-pointer w-full"
          onClick={() => {
            selectIndex(0);
            setModalOpen(false);
          }}
        >
          <span className={`${TYPO.TYPO6_MD} text-gray80`}>
            {"총 수익 높은 순"}
          </span>
          <div className={index !== 0 ? "hidden" : ""}>
            <Icon
              name={ICONS.CHECK}
              fill={index === 0 ? "blue40" : ""}
              width={24}
              height={24}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between py-[6px] cursor-pointer w-full"
          onClick={() => {
            selectIndex(1);
            setModalOpen(false);
          }}
        >
          <span className={`${TYPO.TYPO6_MD} text-gray80`}>
            {"일간 수익률 높은 순"}
          </span>
          <div className={index !== 1 ? "hidden" : ""}>
            <Icon
              name={ICONS.CHECK}
              fill={index === 1 ? "blue40" : ""}
              width={24}
              height={24}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between py-[6px] cursor-pointer w-full"
          onClick={() => {
            selectIndex(2);
            setModalOpen(false);
          }}
        >
          <span className={`${TYPO.TYPO6_MD} text-gray80`}>{"이름 순"}</span>
          <div className={index !== 2 ? "hidden" : ""}>
            <Icon
              name={ICONS.CHECK}
              fill={index === 2 ? "blue40" : ""}
              width={24}
              height={24}
            />
          </div>
        </div>
      </div>
    </BottomFloatingModal>
  );
}

export default MyStockSortModal;
