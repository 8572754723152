import { useKbPassword } from "utils/kb/password";
import AccountInfo from "./Account";
import MyStockLists from "./Stocks";
import { useKBGlobalState } from "contexts/KBContext";
import { useRouter } from "next/router";
import { accountWaitingScreen } from "utils/kb/LoadingScreen";
import { useEffect, useState } from "react";
import {
  useKbAccountBalance,
  useKbWaitingOrderList,
} from "utils/clientAPIs/accountsCheck";
import Loading from "components/organisms/m/Loading";
import KbConnectAd from "./KbConnectAd";
import HighTotalScoreStocks from "./HighTotalScoreStocks";
import MyStockService from "./Service";
import DividerLine from "components/common/divider/line";
import StrateRanking from "./StrateRanking";

function MyStockIndex({
  isMobile,
  isLoginUser,
}: {
  isMobile: boolean;
  isLoginUser: boolean;
}) {
  const router = useRouter();
  const [isValuationError, setIsValuationError] = useState(false);
  const { isAccountLoading, currentAccount, isLoading } = useKBGlobalState();
  const [encryptedPassword, PasswordScreen, inPasswordMode] =
    useKbPassword(isMobile);

  //
  const [balanceData, isBalanceLoading, balacneApiMutate, balanceError] =
    useKbAccountBalance(currentAccount?.accountNo || null, encryptedPassword);
  const [waitingOrderList, isWaitingOrdersLoading, _, waitingError] =
    useKbWaitingOrderList(currentAccount?.accountNo, encryptedPassword);

  if (isBalanceLoading || isWaitingOrdersLoading)
    return (
      <div>
        <div className="bg-white w-full flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );

  if (
    balanceError ||
    isValuationError ||
    balanceData?.balance === null ||
    waitingError
  )
    return <div></div>;

  const scrren = accountWaitingScreen({
    isLoginUser,
    router,
    isAccountLoading,
    currentAccount,
    isLoading,
    inPasswordMode,
    PasswordScreen,
  });
  // if (scrren) return scrren;
  if (!isLoginUser) {
    return (
      <div className="flex flex-col bg-white w-full items-center justify-center gap-4">
        <KbConnectAd />
        <HighTotalScoreStocks />
        <MyStockService isLoginUser={isLoginUser} />
        <DividerLine size="lg" />
        <StrateRanking />
      </div>
    );
  }
  // 계좌 정보 불러오기
  if (isAccountLoading) {
    return (
      <div className="bg-white w-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (!currentAccount)
    return (
      <div className="bg-white w-full flex items-center justify-center flex-col gap-4 min-h-[85vh]">
        <KbConnectAd />
        <HighTotalScoreStocks />
        <MyStockService isLoginUser={isLoginUser} />
        <DividerLine size="lg" />
        <StrateRanking />
      </div>
    );

  if (isLoading && currentAccount) {
    return (
      <div className="bg-white w-full  flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (inPasswordMode) return <PasswordScreen />;

  return (
    <div className="flex flex-col bg-white">
      {!inPasswordMode && (
        <>
          <AccountInfo
            balanceData={balanceData}
            waitingOrderList={waitingOrderList}
          />
          <MyStockLists
            setIsValuationError={setIsValuationError}
            isLoginUser={isLoginUser}
          />
        </>
      )}
    </div>
  );
}

export default MyStockIndex;
