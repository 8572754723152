import Button from "components/common/buttons/buttons";
import Loading from "components/organisms/m/Loading";
import { TYPO } from "styles/Typo";

export const accountWaitingScreen = ({
  isLoginUser,
  router,
  isAccountLoading,
  currentAccount,
  isLoading,
  inPasswordMode,
  PasswordScreen,
}: {
  isLoginUser: boolean;
  router: any;
  isAccountLoading: boolean;
  currentAccount: any;
  isLoading: boolean;
  inPasswordMode: boolean;
  PasswordScreen: any;
}) => {
  if (!isLoginUser) {
    return (
      <div className="flex flex-col bg-white w-full items-center justify-center gap-4">
        <span className={`${TYPO.TYPO6_MD} text-gray80`}>
          로그인 후 이용해주세요{" "}
        </span>
        <Button
          type="primary"
          size="lg"
          clickHandler={() => {
            router.push("/login");
          }}
          text="로그인"
        />
      </div>
    );
  }
  // 계좌 정보 불러오기
  if (isAccountLoading) {
    return (
      <div className="bg-white w-full  flex items-center justify-center">
        <Loading />
      </div>
    );
  }
  console.log("currentAccount", currentAccount);
  console.log("isAccountLoading", isAccountLoading);
  if (!currentAccount)
    return (
      <div className="bg-white w-full  flex items-center justify-center flex-col gap-4 min-h-[85vh]">
        <span className={`${TYPO.TYPO6_MD} text-gray80`}>
          연동한 KB증권 계좌가 없어요
        </span>
        <Button
          type="primary"
          size="lg"
          clickHandler={() => {
            router.push("/kb/welcome");
          }}
          text="연결하기"
        />
      </div>
    );

  if (isLoading && currentAccount) {
    return (
      <div className="bg-white w-full  flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (inPasswordMode) return <PasswordScreen />;
};
